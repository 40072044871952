import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import reduxThunk from 'redux-thunk'
import Dashboard from './Components/Dashboard.jsx'
import { routerMiddleware, ConnectedRouter } from 'connected-react-router'
import reducers from './reducers/reducers'
import RegisterNew from './Components/RegisterNew/RegisterNew';
const createBrowserHistory = require("history").createBrowserHistory

const history = createBrowserHistory()
const store = createStore(
    reducers(history),
    {}, 
    compose(applyMiddleware(routerMiddleware(history),
    reduxThunk))
)

ReactDOM.render(
<Provider store={store}>
    <ConnectedRouter history={history}>
        <Router>
            <Switch>
                <Route path='/' component={Dashboard} />
                
            </Switch>
        </Router>
    </ConnectedRouter>
</Provider>,
document.getElementById('root')
)
